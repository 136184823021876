import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import { GatsbyImage } from 'gatsby-plugin-image';

import Layout from '../components/layout';
import PageTags from '../components/pageTags';

import { useSpring, useTransition, animated } from 'react-spring';

import { useSiteMetadataSizes } from '../hooks/useSiteMetadata';
import CustomUnderline from '../components/customUnderline';

const Container = styled.div`
  margin: 2rem auto;
  max-width: ${({ sizes }) => sizes.desktop - 50}px;

  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-template-rows: 1fr;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { margin: 2rem 3rem; }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { margin: 2rem 2rem; }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { margin: 2rem 1rem; }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const Header = styled.div`
  display: flex;
  column-gap: 5rem;
  row-gap: 1rem;
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { 
    flex-direction: column;
  }
`;

// const Container = styled.div`
//   display: grid;
//   grid-template-columns: 2fr 1fr 5fr;
//   grid-template-rows: 1fr;
//   width: 100vw;
//   height: 100vh;
//   ${'' /* max-width: ${({sizes}) => sizes.desktop}px; */}
//   ${'' /* margin-left: ; */}
//   overflow: hidden;

//   ${'' /* margin-left: 10%; */}

//   @media (max-width: ${({ sizes }) => sizes.desktop}px) {
//     ${'' /* margin: 0; */}
//     grid-template-columns: 1fr 4fr;
//   }
//   @media (max-width: ${({ sizes }) => sizes.laptopL}px) {
//     grid-template-columns: 1fr 3fr;
//   }
//   @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
//   @media (max-width: ${({ sizes }) => sizes.tablet}px)  {
//     display: flex;
//     flex-direction: column;
//     width: auto;
//     height: auto;
//   }
//   @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
//   @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
//   @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
// `;

const Sidebar = styled.div`
  overflow-y: scroll;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  margin-left: 50%;
  margin-right: 1rem;
  
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  @media (max-width: ${({ sizes }) => sizes.desktop}px) {
    justify-content: space-between;
    margin-left: 0%;
  }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  {
    padding: 1rem;
  }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const Sidebar2 = styled.div`
  overflow-y: scroll;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 1rem;
  
  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { display: none }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const SidebarDetails = styled.div`
  display: none;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { display: block;}
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { display: none; }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const FooterDetails = styled.div`
  display: none;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { display: block; padding: 0 1rem 1rem 1rem; }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const SidebarNav = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 2rem 0 0 0;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { display: none; padding: 0; }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const FooterNav = styled.div`
  display: none;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { display: flex; justify-content: space-between; padding: 2rem 0.5rem; }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const Main = styled.div`
  overflow-y: scroll;
  padding: 1rem 20% 2rem 1rem; 
  margin-left: 1rem;
  &::-webkit-scrollbar {
    ${'' /* width: 0px; */}
    ${'' /* background: transparent; */}
  }

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { padding-right: 2rem;}
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { padding: 0 1rem 0 0; }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }
`;

const Content = styled.div`
  margin-right: 0%;

  @media (max-width: ${({ sizes }) => sizes.desktop}px) { margin-right: 0%; }
  @media (max-width: ${({ sizes }) => sizes.laptopL}px) { }
  @media (max-width: ${({ sizes }) => sizes.laptop}px)  { }
  @media (max-width: ${({ sizes }) => sizes.tablet}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileL}px) { }
  @media (max-width: ${({ sizes }) => sizes.mobile}px)  { }
  @media (max-width: ${({ sizes }) => sizes.mobileS}px) { }

  & p {
    margin-left: auto;
    margin-right: auto;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
    max-width: 900px;
    font-size: 11pt;
    line-height: 1.34rem;
    font-weight: 400;
  }

  & figure {
    margin: 0;
  }

  & blockquote {
    margin: auto;
    padding: 0.15rem 1.5rem;
    background: #eee;
    font-style: italic;
    line-height: 1.35rem;
    max-width: 700px;
    font-weight: 300;
  }

  & figcaption {
    color: #888;
    position: relative;
    left: 50%;
    width: 50%;
    text-align: right;
    font-style: italic;
    margin: 1rem 0;
    font-size: 14px;
  }

  & h1 {
    
  }

  & h5 {
    font-weight: 300;
    color: #888;
    position: relative;
    left: 50%;
    width: 50%;
    text-align: right;
    font-style: italic;
    margin: 1rem 0;
    font-size: 14px;
  }

  & table {
    width: 100%;
  }

  & td {
    width: 50%;
  }
`;


export default function ProjectTemplate({ data, pageContext }) {
  const sizes = useSiteMetadataSizes();
  const { page, next, prev } = data;

  const left = useSpring({
    opacity: 1,
    transform: 'translate(0, 0)',
    from: {
      opacity: 0,
      transform: 'translate(-10px, 0)',
    },
    config: {
      mass: 2,
      tension: 110,
      friction: 50,
    },
  });

  const fade = useSpring({
    opacity: 1,
    from: {
      opacity: 0,
    },
    config: {
      mass: 6,
      tension: 170,
      friction: 50,
    },
  });

  return (
    <Layout>
      <Container sizes={sizes}>
        <Header sizes={sizes}>
          <div>
            <h3>{page.frontmatter.name}</h3>
            <h3>{page.frontmatter.caption}</h3>
            {/* <div sizes={sizes} dangerouslySetInnerHTML={{ __html: page.html }} /> */}

            <CustomUnderline>
              <Link to="/">
                <h3>← Home</h3>
              </Link>
            </CustomUnderline>
            { pageContext.prev ? (
              <CustomUnderline>
                <Link to={`/${pageContext.prev}`}>
                  <h3>← Prev</h3>
                </Link>
              </CustomUnderline>
            ) : null}
            { pageContext.next ? (
              <CustomUnderline>
                <Link to={`/${pageContext.next}`}>
                  <h3>→ Next</h3>
                </Link>
              </CustomUnderline>
            ) : null}
          </div>
        </Header>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          {
            page.frontmatter.images ? (
              page.frontmatter.images.map((img) => (
                <div>
                  <GatsbyImage image={img.childImageSharp.gatsbyImageData} alt={page.frontmatter.name} />
                </div>
              ))
          ) : null
          }
        </div>

        {/* <GatsbyImage image={page.frontmatter.images[1].childImageSharp.gatsbyImageData} alt={page.frontmatter.name} /> */}
        {/* <Sidebar sizes={sizes}>
          <animated.div style={left}>
            <CustomUnderline><Link to="/">← index</Link></CustomUnderline>
            <h2>{page.frontmatter.name}</h2>
            <div/>
            <SidebarDetails sizes={sizes}>
              <PageTags frontmatter={page.frontmatter} />
            </SidebarDetails>
          </animated.div>

          <SidebarNav sizes={sizes}>
            { pageContext.prev ? (
              <animated.div style={{ ...left, padding: '0 0.5rem' }}><CustomUnderline><Link to={`/${pageContext.prev}`}>← prev</Link></CustomUnderline></animated.div>
            ) : null}
            { pageContext.next ? (
              <animated.div style={{ ...left, padding: '0 0.5rem' }}><CustomUnderline><Link to={`/${pageContext.next}`}>→ next</Link></CustomUnderline></animated.div>
            ) : null}
          </SidebarNav>
        </Sidebar>

        <Sidebar2 sizes={sizes}>
          <animated.div style={left}>
            <PageTags frontmatter={page.frontmatter} />
          </animated.div>
        </Sidebar2>

        <Main sizes={sizes}>
          <animated.div style={fade}>
            <Content sizes={sizes} dangerouslySetInnerHTML={{ __html: page.html }} />
          </animated.div>
        </Main>

        <FooterDetails sizes={sizes}>
          <PageTags frontmatter={page.frontmatter} />
        </FooterDetails>

        <FooterNav sizes={sizes}>
          { pageContext.prev ? (
            <div style={{ padding: '0 0.5rem' }}><CustomUnderline><Link to={`/${pageContext.prev}`}>← prev</Link></CustomUnderline></div>
          ) : null}
          <CustomUnderline
            onClick={() => window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })}
            onKeyPress={() => window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            })}
            role="button"
          >
            <div>{`↑ top`}</div>
          </CustomUnderline>
          { pageContext.next ? (
            <div style={{ padding: '0 0.5rem' }}><CustomUnderline><Link to={`/${pageContext.next}`}>→ next</Link></CustomUnderline></div>
          ) : null}
        </FooterNav> */}
      </Container>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!, $next: String, $prev: String) {
    page: markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        date
        type
        caption
        header
        clients
        domains
        tools
        name
        links
        linksText
        awards
        awardsText
        press
        pressText
        role
        images {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    }
    next: markdownRemark(fields: {slug: {eq: $next}}) {
      frontmatter {
        name
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
    prev: markdownRemark(fields: {slug: {eq: $prev}}) {
      frontmatter {
        name
        image {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }

`;